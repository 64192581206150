import { createGraphQLClient } from '@solid-primitives/graphql'
import { makeAbortable } from '@solid-primitives/resource'

function useGraphQL(headers: HeadersInit | undefined = undefined) {
	const [signal, abort] = makeAbortable()

	const fetcher = (input: RequestInfo | URL, init?: RequestInit) =>
		fetch(input, { ...init, headers: headers, signal: signal() })

	return createGraphQLClient('/graphql', { fetcher: fetcher })
}

export { useGraphQL }
